<template>
  <div class="container m-auto">
    <div class="flex flex-col md:flex-row">
      <div class="mb-6">
        <div
          class="uk-card uk-card-body uk-card-secondary uk-text-center uk-border-rounded"
        >
          <div class="uk-text-large uk-flex uk-flex-middle uk-flex-center">
            <div v-if="pointsIcon">
              <img :src="pointsIcon" alt="" width="50px" />
            </div>
            <div class="uk-margin-small-left">
              {{ formatThousands(pointsBalance) }}
            </div>
          </div>
          <div>{{ pointsName }} disponibles</div>
        </div>
        <div
          class="uk-margin-top uk-card uk-padding-small uk-card-secondary uk-border-rounded"
        >
          <div class="uk-text-large">Canjear código</div>
          <input
            class="uk-input uk-border-rounded uk-text-secondary uk-text-center"
            name="Cantidad"
            v-model="codeRedeem"
          />
          <div id="redeem-coupon"></div>
          <div class="uk-margin-small-top uk-text-center">
            <button
              class="uk-button uk-button-primary uk-border-rounded"
              @click="redeem"
              :disabled="isLoadingCodeRedeem"
            >
              <span v-if="isLoadingCodeRedeem" uk-spinner></span>
              <span v-else>Canjear</span>
            </button>
          </div>
        </div>
      </div>

      <div class="flex-1 ml-0 md:ml-8">
        <div class="">
          <div class="uk-text-large pb-6">Movimientos</div>
          <pagination-buttons
            :prev-page-url="movements.prev_page_url"
            :is-fetching="isLoadingMovements"
            :current-page="movements.current_page"
            :last-page="movements.last_page"
            :next-page-url="movements.next_page_url"
            style="fle"
          ></pagination-buttons>
        </div>
        <table
          class="uk-table uk-table-striped uk-table-middle uk-table-hover uk-table-divider"
        >
          <thead>
            <tr>
              <th>Fecha</th>
              <th>Fuente</th>
              <th>Monto</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="movement in movements.data" :key="movement.id">
              <td>{{ formatDatetime(movement.timestamp) }}</td>
              <td>
                <div v-if="movement.source_type === 'App\\Models\\PointCode'">
                  Código canjeado: {{ movement.source.code }}
                </div>
                <div v-else-if="movement.source_type === 'App\\Models\\Redeem'">
                  <router-link :to="'/redeem/' + movement.source_id">
                    Compra: {{ movement.source.code }}
                  </router-link>
                </div>
                <div v-else>
                  {{ movement.source_type }} {{ movement.source_id }}
                </div>
              </td>
              <td>
                <div class="uk-flex uk-flex-middle">
                  <div v-if="pointsIcon">
                    <img :src="pointsIcon" alt="" width="50px" />
                  </div>
                  <div class="uk-margin-small-left">
                    {{ formatThousands(movement.amount) }} {{ pointsName }}
                  </div>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import Shepherd from "shepherd.js";

export default {
  name: "PointsIndex",

  data() {
    return {
      movements: {},
      isLoadingMovements: false,
      itemsPerPage: "20",
      page: 1,
      codeRedeem: "",
      isLoadingCodeRedeem: false,
    };
  },

  computed: {
    ...mapGetters([
      "pointsBalance",
      "pointsName",
      "pointsIcon",
      "storefrontStorefront",
    ]),
  },

  methods: {
    ...mapActions(["pointsFetchBalance"]),
    redeem() {
      this.isLoadingCodeRedeem = true;
      this.axios
        .post("/points/redeem", {
          code: this.codeRedeem,
          storefront_id: this.storefrontStorefront.id,
        })
        .then(() => {
          this.codeRedeem = "";
          this.pointsFetchBalance();
          this.fetchPage();
        })
        .catch(() => {})
        .finally(() => {
          this.isLoadingCodeRedeem = false;
        });
    },
    fetchPage() {
      this.isLoading = true;
      this.axios
        .get("/points/movements/" + this.storefrontStorefront.id, {
          params: {
            items: this.itemsPerPage,
          },
        })
        .then(({ data: { movements } }) => {
          this.movements = movements;
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
  },

  mounted() {
    this.fetchPage();

    const tour = new Shepherd.Tour({
      useModalOverlay: true,
      defaultStepOptions: {
        cancelIcon: {
          enabled: true,
        },
        classes: "shadow-md bg-purple-dark",
        scrollTo: { behavior: "smooth", block: "center" },
      },
    });

    tour.addStep({
      id: "coupons",
      title: "Canjea tu cupón",
      text: "Por favor, ingrese su código de cupón para poder canjearlo",
      attachTo: {
        element: "#redeem-coupon",
        on: "right",
      },
    });

    tour.start();
  },

  watch: {
    itemsPerPage() {
      this.movements.current_page = 1;
      this.page = 1;
      this.fetchPage();
    },
  },
};
</script>

<style>
#itemsPerPageId {
  max-width: 255px;
}
</style>
